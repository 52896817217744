import React from "react"
// import { Link } from "gatsby"
import Img from "../components/image-transform"
import classNames from 'classnames'

const compareList = ({compare}) => {
  return (
    <div
      className={classNames('comparison-list-holder', 
      {"bg-blue" : compare.bgColor === "blue"},
      {"bg-gray" : compare.bgColor === "grey"},
      {"section-featured" : compare.sectionFeature === true},
      {"section-zapier" : compare.sectionZapier === true},
      {"section-plan-in-action" : compare.sectionPlanAction === true},)}
    >
      <div className="container">
      {
        compare.content.map(({title, list, image, btnName, link, svg, imageName}, i) => (
          <div 
            id={i}
            className={classNames('comparison-item container-flex', 
            {"col-reverse" : compare.position === "reverse"})} 
            key={i}>
            <div className="content-holder">
              {
                btnName ? <button className="adoric_roadmap btn-gray">{btnName}</button> : ''
              }
              <h2 className="title">{title}</h2>

               {list.map((item, j) =>
                  <div className="details-holder" key={j}>
                    <p className="subtitle">{item.subtitle}</p>
                    <p className="text">{item.text}</p>
                  </div>
                )}
            </div>

            <div className="comparison-image-holder">
              {
                svg ?
                <img src={image} className="img-bg" alt={imageName}/>
                :
                image &&
                <Img filename={image} className="img-bg" alt={imageName}/>
              }
                
              {
                link ? 
                  link.map((link, k) => {
                    return(
                      <div id={k} className="tab-links" key={k}>{link.textLink}</div>
                    )
                  }) : ''
              }
            </div>
          </div>
        ))
      }
      </div>
    </div>
  )
}

export default compareList